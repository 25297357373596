<template>
  <b-modal
    id="SettingListModal"
    title-class="titleMsg"
    body-class="modalBox"
    content-class="p-2"
    title=""
    size="lg"
    ok-title=""
    ok-variant="secondary"
    :hide-header="true"
    :hide-footer="true"
    :centered="true"
    :no-close-on-backdrop="false"
  >
    <div class="title-message">
      <b-form-group label="表示項目を選択してください" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          id="checkbox-group-1"
          @input="checked($event)"
          :checked="selectedItems"
          :options="options"
          :aria-describedby="ariaDescribedby"
          name="flavour-1"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>
  </b-modal>

</template>
<script>

export default {
  name: 'SettingListModal',
  methods: {
    checked(value) {
      this.$store.commit('cmsEvaluationSearch/setHeaderItems', value);
    },
  },
  computed: {
    allHeaderItems() {
      return this.$store.state.cmsEvaluationSearch.allHeaderItems;
    },
    selectedItems() {
      return this.$store.state.cmsEvaluationSearch.selectedHeaderItems;
    },
    options() {
      return this.allHeaderItems.map((data) => {
        return { value: data.key, text: data.label };
      });
    },
  },
};
</script>

<style scoped>


</style>
